export const environment = {
  production: true,
  // BASE_DOMAIN:'https://wms.goalfa.in/',
  BASE_DOMAIN: `${window.location.protocol}//${window.location.hostname}/`,
  New_BASE_DOMAIN: `${window.location.protocol}//${window.location.hostname}/`,
  COOKIE_DOMAIN: 'localhost',
  ORDER_URL:  `${window.location.protocol}//${window.location.hostname}/`,
  WebsocketUrl: `wss://${window.location.hostname}/websocket-service/ws/notifications`
  
};
