<p-toast />
<div class="po-details">
    <div class="modal-header">
    <h4 class="modal-title">
        Change Theme
    </h4>
    </div>
    <div class="modal-body">
        <div class="card border-none">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Select Primary Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedThemeColor" [(colorPicker)]="selectedThemeColor"/>
                                <div [style.background]="selectedThemeColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Select Text Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedMainTextColor" [(colorPicker)]="selectedMainTextColor"/>
                                <div [style.background]="selectedMainTextColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Main Background Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedBgColor" [(colorPicker)]="selectedBgColor"/>
                                <div [style.background]="selectedBgColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Background Type</label>
                            <div class="d-flex">
                                <select name="backgroundType" class="form-control" [(ngModel)]="bgType">
                                    <option value="gradiant">Gradiant</option>
                                    <option value="solid">Solid</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Sidebar/Header Background Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedSidebarBgColor" [(colorPicker)]="selectedSidebarBgColor"/>
                                <div [style.background]="selectedSidebarBgColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Sidebar View</label>
                            <div class="d-flex">
                                <select name="sidebarView" [(ngModel)]="sidebarType" class="form-control" id="">
                                    <option value="left">Left (without collapsed)</option>
                                    <option value="leftCollapsed">Left (with collapsed)</option>
                                    <option value="top">Top</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12" *ngIf="accountId">
                        <div class="form-group">
                            <label>Upload Logo</label>
                            <div class="default-logo">
                                <label for="uploadImg" matTooltip="change logo">
                                    <img [src]="croppedImage ? croppedImage : 'assets/img/logo-main.png' " alt="org logo" pTooltip="Change Logo">
                                    <input id="uploadImg" class="d-none" type="file" (change)="fileChangeEvent($event)" />
                                </label>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12" *ngIf="accountId">
                        <div class="form-group">
                            <label>Upload Small Logo</label>
                            <div class="default-logo" style="width: 50%;">
                                <label for="uploadImgSmall" matTooltip="change logo">
                                    <img [src]="croppedImageSmall ? croppedImageSmall : 'assets/img/logo-small-new.png' " alt="org logo" pTooltip="Change Logo">
                                    <input id="uploadImgSmall" class="d-none" type="file" (change)="fileChangeEventSmall($event)" />
                                </label>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <a href="javascript:void(0);" (click)="submit()" class="btn btn-primary mr-10" >Submit</a>
                            <a href="javascript:void(0);" (click)="reset()" class="btn btn-primary" >Reset</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Crop Image" [modal]="true" [(visible)]="cropImageModal" [style]="{ width: '30rem' }" [draggable]="false" [closable]="false">
    <div class="image-cropper-wrapper">
        <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="4.5 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        [output]="'base64'"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        ></image-cropper>

        <div class="row mt-3">
            <div class="col-lg-12">
                <a href="javascript:void(0);" (click)="saveCroppedImg()" class="btn btn-primary mr-10" >Submit</a>
                <a href="javascript:void(0);" (click)="cropImageModal=false;croppedImage=''" class="btn btn-primary" >Cancel</a>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Crop Image" [modal]="true" [(visible)]="cropImageModalSmall" [style]="{ width: '30rem' }" [draggable]="false" [closable]="false">
    <div class="image-cropper-wrapper">
        <image-cropper
        [imageChangedEvent]="imageChangedEventSmall"
        [maintainAspectRatio]="true"
        [aspectRatio]="30 / 17"
        format="png"
        (imageCropped)="imageCroppedSmall($event)"
        (imageLoaded)="imageLoadedSmall()"
        [output]="'base64'"
        (cropperReady)="cropperReadySmall()"
        (loadImageFailed)="loadImageFailedSmall()"
        ></image-cropper>

        <div class="row mt-3">
            <div class="col-lg-12">
                <a href="javascript:void(0);" (click)="saveCroppedImgSmall()" class="btn btn-primary mr-10" >Submit</a>
                <a href="javascript:void(0);" (click)="cropImageModalSmall=false;croppedImageSmall=''" class="btn btn-primary" >Cancel</a>
            </div>
        </div>
    </div>
</p-dialog>