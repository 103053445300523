import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  private accessControlData: any[] = [];

  constructor() {}

  setAccessControlData(data: any[]): void {
    this.accessControlData = data;
  }

  canRead(subModuleName: string): boolean {
    return this.checkAccess(subModuleName, 'readAccess');
  }

  canWrite(subModuleName: string): boolean {
    return this.checkAccess(subModuleName, 'writeAccess');
  }

  canUpdate(subModuleName: string): boolean {
    return this.checkAccess(subModuleName, 'updateAccess');
  }

  private checkAccess(subModuleName: string, accessType: string): boolean {
    for (const module of this.accessControlData) {
      if (!module.subModules || module.subModules.length === 0) {
        // If subModules is empty, return false
        continue;
      }
      const subModule = module.subModules.find(
        (sm: any) => sm.subModuleName === subModuleName
      );
      if (subModule && subModule[accessType] === 1) {
        return true;
      }
    }
    return false;
  }
}
