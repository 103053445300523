import { Component, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MegaMenuItem, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as feather from 'feather-icons';
import { AuthService } from '../../services/auth.service';
import { ThemeService } from '../../services/theme.service';
import { roleService } from '../../services/rolebase-services';
import { TabService } from '../../tab.service';
import { ChangeColorModalComponent } from '../change-color-modal/change-color-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { svgs } from 'src/app/icons';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-top-sidebar',
  templateUrl: './top-sidebar.component.html',
  styleUrls: ['./top-sidebar.component.css'],
  providers: [MessageService,DialogService]
})
export class TopSidebarComponent {
  
  ref: DynamicDialogRef | undefined;
  isMobile: boolean = false;
  isModuleActive:boolean=false;
  isSubModuleActive:boolean= false;
  items: MenuItem[] = [];
  themeValue = false;
  theme = 'dark'
  svgIcons = svgs
  menu : any;
  roleIds:any;
  showLoader = true
  showLogoutPopup = false
  constructor(private auth: AuthService, public roleService: roleService,public themeService: ThemeService,public router: Router,private sanitizer: DomSanitizer,private sharedService: SharedService,
    private renderer: Renderer2,public dialogService: DialogService,private tabService: TabService) {
    document.body.classList.add('top-sidebar')
    this.checkIfMobile();
  }
  
  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    const theme = localStorage.getItem('darkTheme')
    this.themeValue = theme === 'true' ? true : false
    // this.roleService.fetchRoleList();
    const userData = JSON.parse(localStorage.getItem('user_details') || '{}');
    if (userData.userRole && Array.isArray(userData.userRole)) {
      this.roleIds = userData.userRole.map((role: any) => role.id);
    } else {
      console.log('No userRole data found');
    }
    this.sharedService.updateLoader(true);
    this.getModuleSubmodule(this.roleIds)
    const bgType = localStorage.getItem('bgType');
    const primaryColor = localStorage.getItem('backgroundPrimaryColor')
    document.documentElement.style.setProperty('--background-primary-color', primaryColor);
    document.documentElement.style.setProperty('--background-primary-hover-color', (primaryColor + '14'));
    const mainBgColor = localStorage.getItem('backgroundMainColor')
    if(bgType === 'gradiant'){
      document.body.style.backgroundColor = ''
      document.body.style.setProperty('--main-background-color', mainBgColor);
    }
    else{
      document.body.style.setProperty('--main-background-color', mainBgColor);
      document.body.style.backgroundColor = mainBgColor!
    }
    const sidebarBgColor = localStorage.getItem('backgroundSidebarColor')
    document.documentElement.style.setProperty('--sidebar-background-color', sidebarBgColor);
    const mainTextColor = localStorage.getItem('mainTextColor')
    document.documentElement.style.setProperty('--main-text-color', mainTextColor);
    this.items = [
      {
        label: 'Manage Product',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/product-list"
      },
      {
        label: 'Manage Product Attributes',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/product-attribute"
      },
      {
        label: 'Upload Products',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/upload-product"
      },
    ];
    // this.getWarehouseList()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile(); // Check whenever the window is resized
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 769; 
  }

  toggleNavMobile() {
    if (this.isMobile) {
      const toggleBtn: any = document.getElementById('mobile_btn');

      const body = document.body;
      // const wrapper = document.querySelector(".main-wrapper");

      if (document.querySelector(".slide-nav")) {
        document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

        body.classList.remove('menu-opened');
        // toggleBtn.classList.add('active');
        // localStorage.setItem(screenModeNightTokenState, 'night');
        setTimeout(() => {
          body.classList.remove('mini-sidebar');
          document.querySelector(".header-left")?.classList.add("active");
        }, 100);
      } else {
        document.querySelector(".main-wrapper")?.classList.add("slide-nav");

        body.classList.add('menu-opened');
        toggleBtn.classList.remove('active');
        // localStorage.removeItem(screenModeNightTokenState);
        setTimeout(() => {
          body.classList.add('mini-sidebar');
          document.querySelector(".header-left")?.classList.remove("active");
        }, 100);
      }
    }
  }
  logOut() {
    this.auth.logOut()
  }
  toggleTheme() {
    this.themeService.toggleTheme();
  }

  // getWarehouseList() {
  //   // const userDetails = this.getUserDetails
  //   // this.getUserDetails = JSON.parse(userDetails)
  //   const id = 0
  //   // const extractedIds = this.getUserDetails?.userWarehouseMapping.map((item: any) => item.warehouseId);
  //   this.auth.getWarehouseId(id).subscribe((res: any) => {
  //     const wareHouseList = res?.responseObject
  //     localStorage.setItem('wareHouseList', JSON.stringify(wareHouseList))
  //   })
  // }

  changeTheme(){
    this.ref = this.dialogService.open(ChangeColorModalComponent, {
      // header: 'Select a Product',
      width: '35vw',
      data: {
        type: 'user'
      },
      contentStyle: { overflow: 'auto' },
      draggable: true
      // breakpoints: {
      //   '960px': '75vw',
      //   '640px': '90vw',
      // },
      // templates: {
      //   footer: Footer,
      // },
    });
  
    this.ref.onClose.subscribe((data: any) => {
      let summary_and_detail;
      if (data) {
        setTimeout(() => {
          this.showLogoutPopup = true
        }, 100);
      } else {
        
      }
      // this.messageService.add({
      //   severity: 'info',
      //   ...summary_and_detail,
      //   life: 3000,
      // });
    });
  }
  addNewTab(item:any) {
    this.tabService.addTab({
      label: item.subModuleName, path: item.subModuleEndpoint,
      fullPath: '',
      filterData: []
    });
  }
  getModuleSubmodule(ids:any){
    this.auth.getModuleSubmodule(ids).subscribe((res:any)=>{
      this.menu = res.responseObject
      this.menu.forEach((el:any)=>{
        const moduleIcon = el.moduleIcon; 
            if (svgs[moduleIcon]) {
              el.icon = this.sanitizer.bypassSecurityTrustHtml(svgs[moduleIcon]);
            }
        el.subModules.forEach((ele:any)=>{
          const subModuleIcon = ele.subModuleIcon; 
          if (svgs[subModuleIcon]) {
            ele.icon = this.sanitizer.bypassSecurityTrustHtml(svgs[subModuleIcon]);
          }
        })
      })
      this.showLoader = false
      this.sharedService.updateLoader(false);
    },(error:any)=>{
      this.sharedService.updateLoader(false);
      this.showLoader = false
    })
  }
}
