import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Tab {
  label: string;
  path: string;
  fullPath: string;  // Store full path including parameters and query params
  filterData: any;
  params?: any; // Added to support optional parameters
}

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private storageKey = 'tabs';
  private tabsSubject: BehaviorSubject<Tab[]>;
  private activeTabSubject: BehaviorSubject<number>;

  tabs$: any;
  activeTab$: any;

  constructor() {
    const savedTabs = localStorage.getItem(this.storageKey);
    const initialTabs = savedTabs ? JSON.parse(savedTabs) : [];
    this.tabsSubject = new BehaviorSubject<Tab[]>(initialTabs);

    const savedActiveTab = localStorage.getItem(this.storageKey + '_active');
    const initialActiveTab = savedActiveTab ? Number(savedActiveTab) : 0;
    this.activeTabSubject = new BehaviorSubject<number>(initialActiveTab);

    this.tabs$ = this.tabsSubject.asObservable();
    this.activeTab$ = this.activeTabSubject.asObservable();
  }

  private saveTabs() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.tabsSubject.value));
  }

  private saveActiveTab() {
    localStorage.setItem(this.storageKey + '_active', this.activeTabSubject.value.toString());
  }

  get tabs(): Tab[] {
    return this.tabsSubject.value;
  }

  get activeTab(): number {
    return this.activeTabSubject.value;
  }

  addTab(tab: Tab) {
    // Generate the full path including parameters if provided
    const pathWithParams = tab.params
      ? `${tab.path}?${new URLSearchParams(tab.params).toString()}`
      : tab.path;

    tab.fullPath = pathWithParams; // Ensure fullPath reflects the path and params

    const existingTab = this.tabs.findIndex(t => {
      if (t.path.includes('receiving-services/asn') && tab.path.includes('receiving-services/asn')) {
        t.fullPath = pathWithParams; // Update fullPath if similar tab exists
      }
      if (t.path.includes('receiving-services/putaway') && tab.path.includes('receiving-services/putaway')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('receiving-services/grn') && tab.path.includes('receiving-services/grn')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('order-services/picklist') && tab.path.includes('order-services/picklist')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('order-services/order') && tab.path.includes('order-services/order')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('order-services/manifest') && tab.path.includes('order-services/manifest')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('order-services/edit-order') && tab.path.includes('order-services/edit-order')) {
        t.fullPath = pathWithParams;
      }
      if (t.path.includes('utility/upload') && tab.path.includes('utility/upload')) {
        t.fullPath = pathWithParams;
      }
      // Updated to match based on fullPath (path + params)
      return t.fullPath === pathWithParams;
    });

    if (existingTab === -1) {
      this.tabsSubject.next([...this.tabs, tab]);
      this.setActiveTab(this.tabs.length);
    } else {
      this.setActiveTab(existingTab);
    }
    this.saveTabs();
    this.saveActiveTab();
  }

  setActiveTab(index: number) {
    this.activeTabSubject.next(index);
    this.saveActiveTab();
  }

  updateTab(index: number, tab: Tab) {
    const tabs = [...this.tabs];
    if (tabs.length > 0) {
      tabs[index] = tab;
      this.tabsSubject.next(tabs);
      this.saveTabs();
    }
  }

  closeTab(index: number) {
    const tabs = [...this.tabs];
    tabs.splice(index, 1);
    this.tabsSubject.next(tabs);
    this.saveTabs();
    if (this.activeTab === index) {
      if (tabs.length > 0) {
        this.setActiveTab(index === 0 ? 0 : index - 1);
      } else {
        this.setActiveTab(0);
      }
    } else if (this.activeTab > index) {
      this.setActiveTab(this.activeTab - 1);
    }
    this.saveActiveTab();
  }

  closeAllTab() {
    const tabs: Tab[] = [];
    this.tabsSubject.next(tabs);
    this.saveTabs();
  }

  updateFilterData(index: number, filterData: any) {
    const tabs = [...this.tabs];
    if (tabs.length > 0) {
      tabs[index].filterData = filterData;
      this.tabsSubject.next(tabs);
      this.saveTabs();
    }
  }

  transformFiltersArrayToObject(filtersArray: any[]): any {
    return filtersArray.reduce((acc, curr) => {
      if (curr.value || curr.value == '0') {
        acc[curr.key] = curr.value;
      }
      if (curr.dateTimeValue) {
        acc[curr.key] = curr.dateTimeValue;
      }
      return acc;
    }, {});
  }
}
