import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { Configuration } from './constants/constants/config';
import { SharedModule } from './shared/shared.module';
import { ResponseInterceptor, responseProvider } from './_interceptor/response.interceptor';
import { TokenInterceptor, tokenProvider } from './_interceptor/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AppMaterialModule } from './_material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { OrderReportComponent } from './dashboard/component/order-report/order-report.component';
import { ChartModule } from 'primeng/chart';
import { ClickOutsideModule } from 'ng-click-outside';
import { PanelMenuModule } from 'primeng/panelmenu';
import { EditorModule } from 'primeng/editor';
import { ImportsPrimeModule } from './_material/primeng.module';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { WarehouseConfiguration } from './constants/constants/warehouse-api-config';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TopSidebarComponent } from './shared/component/top-sidebar/top-sidebar.component';
import { PagenotfoundComponent } from './shared/component/pagenotfound/pagenotfound.component';

// import { DatepipePipe } from './_pipe/datepipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    SidebarComponent,
    TopSidebarComponent,
    HeaderComponent,
    PagenotfoundComponent,
    // DatepipePipe,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AppMaterialModule,BrowserAnimationsModule,ChartModule,
    ClickOutsideModule,
    PanelMenuModule,
    EditorModule,
    ImportsPrimeModule,
    DynamicDialogModule,
    ToastModule
  ],
  exports:[ 
    HeaderComponent,
    SidebarComponent,
    ToastModule,
    PagenotfoundComponent
  ],
  providers: [AuthService,Configuration,DatePipe,MessageService,
    tokenProvider,responseProvider,CookieService,DialogService,WarehouseConfiguration
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
