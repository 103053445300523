<div class="sidebar" id="sidebar " [ngClass]="[theme === 'dark'? 'dark' : '', sidebarType === 'leftCollapsed' ? 'leftCollapsed' : '']">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul *ngIf="!showLoader">
                 <li class="submenu-open" *ngFor="let item of menu" routerLinkActive="activeMenu" [ngClass]="{'d-none': item.subModules.length==0}" (click)="showHideSettingsModule = false"> 
                    <h6  class="submenu-hdr" [routerLink]="item.subModules.length>0 ? item.subModules[0].subModuleEndpoint : ''">
                        <span>
                            {{item.moduleName}} 
                        </span>
                        <span *ngIf="sidebarType === 'leftCollapsed'" class="arrows" [innerHTML]="sanitizer.bypassSecurityTrustHtml(svgIcons.down_arrow)"></span>
                    </h6>
                    <ul routerLinkActive = 'openNav'>
                        <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let submenu of item.subModules"> -->
                            <li *ngFor="let submenu of item.subModules" (click)="addNewTab(submenu)">
                                <span>
                                    <a [routerLink]="[submenu.subModuleEndpoint]" routerLinkActive="active" id="mobile_btn" (click)="toggleNavMobile()">
                                        <span [ngClass]="{'active-icon': submenu.subModuleEndpoint === router.url}" [innerHTML]="submenu.icon"></span>
                                        <!-- <img class="mr-10" [src]="submenu.url === router.url ? submenu.activeIcon : submenu.icon "  alt=""> -->
                                        <span>{{submenu.subModuleName}}</span></a>
                                    </span>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open">
                    <h6 class="submenu-hdr" (click)="showSettingsModule()" [ngClass]="{'activeMenu': showHideSettingsModule}">
                        <span>
                            Settings
                        </span>
                        <span *ngIf="sidebarType === 'leftCollapsed'" class="arrows" data-feather="chevron-down"></span>
                    </h6>
                    <ul [ngClass]="{'openNav': showHideSettingsModule}">
                        <li>
                            <a (click)="logOut()" title="Logout">
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.2429 22 18.8286 22 16.0002 22H15.0002C12.1718 22 10.7576 22 9.87889 21.1213C9.11051 20.3529 9.01406 19.175 9.00195 17" stroke="#313131" stroke-width="1.5" stroke-linecap="round"></path> <path d="M15 12L2 12M2 12L5.5 9M2 12L5.5 15" stroke="#313131" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                <span>Logout</span> 
                            </a>
                        </li>
                        <li>
                            <a (click)="changeTheme()" title="Change Theme">
                                <svg fill="#313131" width="18" height="18" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#313131"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M517.257 1127.343c72.733 0 148.871 36.586 221.274 107.45 87.455 110.418 114.922 204.135 81.632 278.296-72.733 162.274-412.664 234.897-618.666 259.178 34.609-82.62 75.15-216.88 75.15-394.645 0-97.123 66.47-195.455 157.88-233.689 26.698-11.097 54.494-16.59 82.73-16.59Zm229.404-167.109c54.055 28.895 106.462 65.371 155.133 113.494l13.844 15.6c28.016 35.378 50.649 69.987 70.425 104.155-29.554 26.259-59.878 52.737-90.75 79.545-18.898-35.488-43.069-71.964-72.843-109.319l-4.285-4.834c-48.342-47.683-99.43-83.39-151.727-107.011 26.368-30.653 53.066-61.196 80.203-91.63Zm1046.49-803.133c7.801 7.8 18.129 21.754 16.92 52.187-6.043 155.683-284.338 494.405-740.509 909.266-19.995-32.302-41.969-64.822-67.788-97.453l-22.523-25.27c-49.22-48.671-101.408-88.883-156.012-121.074 350.588-385.855 728.203-734.356 910.254-741.828 30.983-.109 44.497 9.01 59.658 24.172Zm126.678 56.472c2.087-53.615-14.832-99.98-56.142-141.29-34.28-34.279-81.962-51.198-134.588-49.11-304.554 12.414-912.232 683.377-1179.54 996.17-53.616-5.383-106.682 2.088-157.441 23.402-132.61 55.263-225.339 193.038-225.339 334.877 0 268.517-103.935 425.737-104.923 427.275L0 1896.747l110.307-6.153c69.217-3.735 681.29-45.375 810.165-332.46 24.39-54.604 29.225-113.163 15.93-175.239 374.32-321.802 972.11-879.71 983.427-1169.322" fill-rule="evenodd"></path> </g></svg>
                                <span>Change Theme</span> 
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="mobilview">
                                    <input type="checkbox" class="checkbox" id="checkbox1" [(ngModel)]="themeValue" (click)="toggleTheme()">
                                    <label for="checkbox1" class="checkbox-label">
                                      <i data-feather="moon" class="fas fa-moon"></i>
                                      <i data-feather="sun" class="fas fa-sun"></i>
                                      <span class="ball"></span>
                                    </label>
                                  </div>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="sidebar-Footer">
        <p >Powered By</p>
        <img style="width: 80px;" src="assets/img/logo-main.png" alt="">
    </div>
</div>

<p-dialog header="Information" [(visible)]="showLogoutPopup" class="popupOpen" [breakpoints]="{ '960px': '50vw' }" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <div class="d-flex align-items-center flex-column">
        <svg style="width:50px;margin-bottom:15px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#7a7a7a" stroke="#7a7a7a"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#7a7a7a" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"></path></g></svg>
        <p>
            To access the newly added theme for customers, please <b style="font-weight: 700;">log out</b> and log back in.
        </p>
    </div>
    <div class="page-header d-flex justify-content-center">
        <button pButton type="button" (click)="logOut()" label="Logout" class="btn btn-added ms-2"></button>
    </div>
</p-dialog>