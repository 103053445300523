import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BASE_DOMAIN = environment.BASE_DOMAIN
  ACCESS_TOKEN = 'access_token';
  USER_DETAILS = 'user_details'; 
  private activeModules: string[] = [];
  private activeSubmodules: string[] = [];

  constructor(private api : ApiService,private API_URL: Configuration,private http: HttpClient,private _route:Router
    ,private cookieService: CookieService
    ) { }


    /**
   * @author Anup Kumar
   * @description sets user authentication token
   * @param {string} accessToken authentication token
   */
  setAuth(accessToken:any) {
    // console.log("token",accessToken)
    this.cookieService.set(this.ACCESS_TOKEN, accessToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  } 
  userDetailsSet(userDetails:any) {
    // console.log("userDetails",userDetails)
    this.cookieService.set(this.USER_DETAILS, JSON.stringify(userDetails), 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  } 

  removeAuthToken() {
    // Remove token from storage
    this.cookieService.delete(this.ACCESS_TOKEN);
  }

  /**
   * @author Anup Kumar
   * @description returns user authentication token
   * @return {string} null if token not present
   */
  getAuthToken() {
    if (this.cookieService.get(this.ACCESS_TOKEN) && this.cookieService.get(this.ACCESS_TOKEN) !== '') {
      return this.cookieService.get(this.ACCESS_TOKEN);
    } else {
      return null;
    }
  }

  getUserDetails() {
    if (this.cookieService.get(this.USER_DETAILS) && this.cookieService.get(this.USER_DETAILS) !== '') {
      return this.cookieService.get(this.USER_DETAILS);
    } else {
      return null;
    }
  }
  signUp(user:any) {
    return this.api.post(this.BASE_DOMAIN+`${this.API_URL.CONFIG_URL.SIGNUP}`, user);
  }
  getUser(getUser:any) {
    // console.log("user",getUser)
    return this.api.post(environment.New_BASE_DOMAIN+`${this.API_URL.CONFIG_URL.USER_LOGIN}`, getUser);
  }

  getClientById(){
    // console.log("__________",id)
    const url = `${environment.ORDER_URL}${this.API_URL.CONFIG_URL.GET_CLIENTS_BY_ID}`;
    return this.api.get(url);
  }
 
  getAllStatus() {
    const url = `${environment.New_BASE_DOMAIN}oms-service/master/getOrderStatusMaster`;
    return this.api.get(url);
  }

  createOrder(payload:{},id:any){
    if(id){
      const url = `${environment.ORDER_URL}${this.API_URL.CONFIG_URL.EDIT_ORDER}`;
      return this.api.post(url,payload);
    }
    else{
      const url = `${environment.ORDER_URL}${this.API_URL.CONFIG_URL.CREATE_ORDER}`;
      return this.api.post(url,payload);
    }
  }
  getOrderById(id:any){
    const sendId = encodeURIComponent(id)
    const url = `${environment.ORDER_URL}${this.API_URL.CONFIG_URL.GET_ORDER_BY_ID}${sendId}`;
    return this.api.get(url);
  }

  getWarehouseId(ids:any){
    var url :any

    url = `${environment.New_BASE_DOMAIN}warehouse-service/info/getMultiWarehouseById`
    if(ids){
      url += `?warehouseList=${ids}`
    }

    // const url = `${environment.New_BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_MULTI_WAREHOUSE_BY_ID}`;
    return this.api.post(url , ids);
  }
  getMultiClientById(ids:any){
    console.log("__________",ids)
    let clientList = '?clientList='
    const urlids = '' 
    if(ids){
      const urlids = clientList + ids
    }
    console.log(urlids)
    const url = `${environment.ORDER_URL}${this.API_URL.CONFIG_URL.GET_MULTI_CLIENTS_BY_ID}${urlids}`;
    console.log(url)

    return this.api.post(url,urlids);
  }

  getRoleList(){
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.ROLE_MAPING}`;
    return this.api.get(url);
  }

  getUserList(filter:any,pageNumber:number,pageSize:number) {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.USER_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url,filter);
  }

  createUser(form:any) {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.CREATE_USER}`;
    return this.api.post(url,form);
  }

  updateUser(form:any) {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.UPDATE_USER}`;
    return this.api.put(url,form);
  }

checkUser(user:any) {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.CHECK_USER}${user}`;
    return this.api.get(url);
  }

  logOut(){
    document.documentElement.style.setProperty('--background-primary-color', '#973CB6');
    document.documentElement.style.setProperty('--background-primary-hover-color', '#973CB614');
    document.body.style.setProperty('--main-background-color', '#f6f4f4');
    document.documentElement.style.setProperty('--sidebar-background-color', '#ffffff');
    document.documentElement.style.setProperty('--main-text-color', '#313131');
    this.cookieService.deleteAll('/', environment.COOKIE_DOMAIN);
    console.log('logout services')
    localStorage.clear()
    this._route.navigate([''])
  }
    // fee detail page is ot using this one
    // getCategoryList(data) {
    //   return this.api.get(` https://v12-dot-payment-reco-dev.appspot.com/_ah/api/epr/v1/getCategory?portalId=` + data);
    // }
    getAccountList(pageNumber:number,pageSize:number,filter:{}) {
      const url = `${environment.New_BASE_DOMAIN}account-service/info/getAccounts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      return this.api.post(url,filter);
    }
    getChannelList(){
      const url = `${environment.New_BASE_DOMAIN}oms-service/channel/getChannelList`;
      return this.api.get(url);
    }
    getOrderList(){
      const url = `${environment.New_BASE_DOMAIN}oms-service/order/getOrderTypeList`;
      return this.api.get(url);
    }
    retryAllocateOrder(orderNumber:any) {
      const order = encodeURIComponent(orderNumber)
      const url = `${environment.New_BASE_DOMAIN}/oms-service/order/retryOrderAllocation?orderNumber=${order}`;
      return this.api.post(url,[]);
    }
    getModuleSubmodule(ids:any){
      const url = `${environment.New_BASE_DOMAIN}auth-service/role/getModulesByRoleId?roleIds=${ids}&platform=wms`;
      return this.api.get(url);
    }

    getCaptcha(){
      const url = `${environment.New_BASE_DOMAIN}captcha-service/captcha/generate`;
      const headers = new HttpHeaders({
        'DEVICE-TYPE': 'Web',
        'VER': '1'
      });
      return this.api.get(url,{headers})
    }
    submitCaptcha(captchaId:any,inputId:any){
      const order = encodeURIComponent(captchaId)
      const url = `${environment.New_BASE_DOMAIN}/captcha-service/captcha/validate?captchaId=${captchaId}&input=${inputId}`;
      const headers = new HttpHeaders({
        'DEVICE-TYPE': 'Web',
        'VER': '1'
      });
      return this.api.post(url,[],{headers});
    }
    uploadPassword(data:any){
      const url = `${environment.New_BASE_DOMAIN}${this.API_URL.CONFIG_URL.CHANGEPASSWORD}`;
      return this.api.put(url,data);
    }
    cancelOrder(orderNumber:any, cancelReason:any, additionalRemarks:any){
      const ordernumber = encodeURIComponent(orderNumber)
      const cancelreason = encodeURIComponent(cancelReason)
      const additionalremarks = encodeURIComponent(additionalRemarks)
      const url = `${environment.New_BASE_DOMAIN}/oms-service/order/cancelOrder?orderNumber=${ordernumber}&cancelReason=${cancelreason}&additionalRemarks=${additionalremarks}`;
      return this.api.post(url,[]);
    }
    getCancelReason(accountId:any){
      const url = `${environment.New_BASE_DOMAIN}oms-service/master/getCancelReasonMaster?accountId=${accountId}`;
      return this.api.get(url);
    }
}
